$icon-font-path: '../../node_modules/bootstrap-sass/assets/fonts/bootstrap/';
@import 'node_modules/bootstrap-sass/assets/stylesheets/_bootstrap';

html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

#canvas {
  width: calc(100% - 300px);
  height: 100%;
  transition: background-color 0.2s;

  canvas {
    width: 100%;
    height: 100%;
    transition: opacity 0.2s;
    z-index: 1;
  }

  #ruler {
    .distance {
      z-index: 2;
      position: absolute;
      display: block;
      width: 60px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      margin-top: -10px;
      margin-left: -30px;
      font-family: Tahoma, monospace;
      font-size: 12px;
      font-weight: normal;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .line {
      z-index: 2;
      position: absolute;
      box-sizing: border-box;
      border: 1px solid #000;
      border-top: 1px dashed #000;
      border-bottom: none;
      height: 10px;
      margin-top: -5px;
      &.straight {
        border-top: 1px solid #000;
      }
    }
  }

  &.click {
    cursor: pointer;
    background-color: #afa;
    canvas {
      opacity: 0.8;
    }
  }

  &.move {
    cursor: grab;
    canvas {
      opacity: 0.8;
    }
  }

  &.moving {
    cursor: grabbing;
    canvas {
      opacity: 0.8;
    }
  }

  &.add {
    cursor: copy;
    canvas {
      opacity: 0.8;
    }
  }

  &.deny {
    cursor: no-drop;
    background-color: #faa;
    canvas {
      opacity: 0.8;
    }
  }
}

#menu {
  position: absolute;
  overflow-y: scroll;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  padding: 20px;
  h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: 300;
    strong {
      font-weight: normal;
      color: $brand-primary;
    }
  }
  .active {
    color: darken($brand-success, 10%);
    text-decoration: underline;
  }
  .actions {
    .panel-body {
      padding: 15px 0 5px 15px;
      .btn-sm {
        width: 100px;
        padding: 5px;
      }
    }
  }
  .glyphicon {
    width: 22px;
  }
  .btn {
    margin-right: 10px;
  }
  .panel-body {
    padding-bottom: 5px;
  }
  table {
    margin-bottom: 0;
    td {
      font-size: 13px;
      border-top: 0;
      border-bottom: 1px solid #ddd;
    }
    tr:last-child {
      td {
        border-bottom: 0;
      }
    }
  }
}
